import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Input,
  Button,
  Text,
  VStack,
  Spinner,
  Container,
  Card,
  CardBody,
  useToast,
  Textarea,
  Heading
} from '@chakra-ui/react';
import Markdown from 'react-markdown'
import OpenAI from 'openai';
import { MessageContentImageFile, MessageContentText } from 'openai/resources/beta/threads/messages/messages';
import { Run } from 'openai/resources/beta/threads/runs/runs';
import config from '../config/config';
import { Recipe, addRecipe } from '../api/recipes/recipes';
import useCurrentUser from '../api/auth/useCurrentUser';
import { User } from '../store/AuthProvider';


const ASSISTANT_ID = config.RECIPE_API_ASSISTANT;

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

interface Message {
  role: 'user' | 'assistant';
  content: MessageContentText | MessageContentImageFile;
}

const ChatMessage = memo(({ role, content }: Message) => {
  console.log({role, content})
  const isUser = role === 'user';

  return (
    <Box
      bg={isUser ? '#68a0bd' : '#639899'}
      p='10px'
      borderRadius='10px'
      maxWidth='80%'
      ml={isUser ? 'auto' : '0'}
      mr={!isUser ? 'auto' : '0'}
      mb='10px'
      alignSelf={isUser ? 'flex-end' : 'flex-start'}
    >
      <Text textAlign={isUser ? 'right' : 'left'} paddingLeft='20px'>
        <Markdown>
          {content.type === 'text'
            ? content.text.value
            : content.image_file.file_id}
        </Markdown>
      </Text>
    </Box>
  );
});

export const ChatPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [thread, setThread] = useState<any>();

  const user = useCurrentUser();

  useEffect(() => {
    const fetchAssistantAndThread = async () => {
      console.log('Fetching assistant and creating thread...');
      try {
        await openai.beta.assistants.retrieve(ASSISTANT_ID);
        setThread(await openai.beta.threads.create({}));
      } catch (error) {
        console.error('Error fetching assistant or creating thread:', error);
      }
    };
    fetchAssistantAndThread();
  }, []);


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const sendMessage = async () => {
    if (!inputValue.trim()) return;

    setInputValue('');
    setIsLoading(true);

    let inputMessage: Message = {
      role: 'user',
      content: {
        type: 'text',
        text: {
          value: inputValue
        }
      } as MessageContentText
    };

    setMessages(messages => [...messages, inputMessage]);

    await openai.beta.threads.messages.create(
      thread.id,
      {
        role: "user",
        content: inputValue
      }
    );

    var run: Run = await openai.beta.threads.runs.create(
      thread.id,
      {
        assistant_id: ASSISTANT_ID
      }
    );

    await waitForRunCompletion(user, thread.id, run.id);

    const assistantMessageList = await openai.beta.threads.messages.list(thread.id);
    const assistantMessage = assistantMessageList.data[0].content[0];

    setMessages(messages => [...messages, {
      role: 'assistant',
      content: assistantMessage as MessageContentText | MessageContentImageFile
    }]);

    setIsLoading(false)
  };

  return (
    <Box maxW="960px" mx="auto" py={8} px={4}>
      <Heading as="h3" size="lg" fontFamily="Roboto Slab" mb={4}>
        Recipe Assistant
      </Heading>
      <VStack spacing={4}>
        {messages.map((message, index) => (
          <ChatMessage key={index} role={message.role} content={message.content} />
        ))}
        {isLoading && (
          <Box display='flex' justifyContent='center'>
            <Spinner />
          </Box>
        )}
      </VStack>
      <Box
        as="form"
        display='flex'
        alignItems='center'
        mt='20px'
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage();
        }}
      >
        <Textarea // Changed from Input to Textarea
          flex={1}
          colorScheme='teal'
          focusBorderColor='teal.500'
          variant="outline"
          placeholder="What recipe are you making?"
          value={inputValue}
          //@ts-ignore
          onChange={handleInputChange}
          size='sm'
          resize='both' // Optional, if you want to disable manual resizing
          autoResize // Enable automatic resizing
        />
        <Button colorScheme="teal" size="md" variant="solid" onClick={sendMessage} marginLeft='5px'>
          Send
        </Button>
      </Box>
    </Box>
  );
};

const waitForRunCompletion = async (user: User, threadId: string, runId: string): Promise<Run> => {
  let runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);

  if (runStatus.status === 'requires_action' && runStatus.required_action?.submit_tool_outputs.tool_calls[0]) {
    const apiInputJSON: Recipe = JSON.parse(runStatus.required_action.submit_tool_outputs.tool_calls[0].function.arguments);
    const finalRecipe: Recipe = {
      ...apiInputJSON,
      author: user.username || 'thecookbook'
    }
    const recipeId: string = await addRecipe(finalRecipe);

    console.log(recipeId);

    runStatus = await openai.beta.threads.runs.submitToolOutputs(threadId, runId, {
      tool_outputs: [
        {
          tool_call_id: runStatus.required_action.submit_tool_outputs.tool_calls[0].id,
          output: recipeId
        }
      ]
    });
  }

  if (runStatus.status !== 'completed') {
    await new Promise(resolve => setTimeout(resolve, 5000));
    return waitForRunCompletion(user, threadId, runId);
  }

  return runStatus;
};



export default ChatPage;