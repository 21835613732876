import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Divider,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import { getRecipe, Recipe } from "../api/recipes/recipes";
import NotFound from "./NotFound";
import { SpinnerIcon } from "@chakra-ui/icons";
import useCurrentUser from "../api/auth/useCurrentUser";
import { getComments, Comment, addComment } from "../api/comments/comments";
import { CommentCard } from "../components/comment/Comment";

interface RecipePageProps {
  id: string;
}

const RecipePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [recipe, setRecipe] = useState<Recipe>();
  const [comments, setComments] = useState<Comment[]>();
  const [comment, setComment] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useCurrentUser();

  let handleInputChange = (e: any) => {
    let inputValue = e.target.value;
    setComment(inputValue);
  };

  let handleSubmit = async (e: any) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (user && comment) {
      try {
        await addComment({
          commentId: uuid(),
          recipeId: id as string,
          author: user.username,
          text: comment,
          timestamp: new Date().toISOString(),
        });
        setComment("");
        //Set comments variable to the new comment and the previous comments
        getComments(id as string).then((comments) => setComments(comments));
        setIsSubmitting(false);
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        if (id) {
          const recipeData = await getRecipe(id as string);
          const commentsData = await getComments(id as string);
          setComments(commentsData);
          setRecipe(recipeData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching recipe:", error);
        setIsLoading(false);
      }
    };

    fetchRecipe();
  }, [id]);

  // Use the useBreakpointValue hook to set the image width
  const imageWidth = useBreakpointValue({ base: "100%", md: "75%", lg: "50%" });

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Icon as={SpinnerIcon} />
      </Flex>
    );
  }

  if (!recipe) {
    return <NotFound message="Oops, page not found!" />;
  }

  return (
    <Box maxW="960px" mx="auto" py={8} px={4}>
      <Box shadow={"md"} borderRadius="md" px={8} py={6}>
        <Heading as="h1" size="xl" mb={2}>
          {recipe.title}
        </Heading>
        <Heading as="h4" size="xs" mb={2}>
          by {recipe.author}
        </Heading>
        <Flex alignItems="center" mb={6}>
          <Text fontSize="sm" fontWeight="bold" mr={2}>
            Category:
          </Text>
          <Text fontSize="sm">{recipe.category}</Text>
        </Flex>
        {recipe.imageUrl && (
          <Box px={10} mb={8}>
            <Center>
              <Image
                src={recipe.imageUrl}
                width={imageWidth}
                loading="lazy"
              ></Image>
            </Center>
          </Box>
        )}
        <Box mb={4}>
          <Text fontSize="lg">{recipe.description}</Text>
        </Box>
        <Box mb={4}>
          <Text fontSize="md" fontWeight="bold" mb={2}>
            Ingredients:
          </Text>
          <Text>
            {Array.isArray(recipe.ingredients) ? (
              recipe.ingredients.map((ingredient, index) => (
                <React.Fragment key={index}>
                  - {ingredient}
                  <br />
                </React.Fragment>
              ))
            ) : (
              typeof recipe.ingredients === 'string' &&
              //@ts-ignore
              recipe.ingredients.split(',').map((ingredient, index) => (
                <React.Fragment key={index}>
                  - {ingredient.trim()}
                  <br />
                </React.Fragment>
              ))
            )}
          </Text>

        </Box>
        <Box>
          <Text fontSize="md" fontWeight="bold" mb={2}>
            Instructions:
          </Text>
          <Text>
            {Array.isArray(recipe.instructions) ? (
              recipe.instructions.map((instruction, index) => (
                <React.Fragment key={index}>
                  {index + 1}. {instruction}
                  <br />
                </React.Fragment>
              ))
            ) : (
              typeof recipe.instructions === 'string' &&
              //@ts-ignore
              recipe.instructions.split('\n').map((instruction, index) => (
                <React.Fragment key={index}>
                  {instruction.trim()}
                  <br />
                </React.Fragment>
              ))
            )}
          </Text>

        </Box>
      </Box>
      <Box py={6}>
        <Divider />
      </Box>
      <Heading as={"h4"} size="md" pb={4}>
        <Text>Comments</Text>
      </Heading>
      <VStack spacing={3} align="stretch">
        {comments?.map((comment, index) => (
          <CommentCard key={index} comment={comment} />
        ))}
        {user.username && (
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <Flex alignItems="center">
                  <Avatar src={user.username} size="md" mr={4} />
                  <Text fontWeight="bold">{user.username}</Text>
                </Flex>
                <Textarea
                  placeholder="Add a comment"
                  value={comment}
                  onChange={handleInputChange}
                  mt={2}
                  my={4}
                />
                <Flex justifyContent="flex-end">
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                    color="teal"
                    size="sm"
                  >
                    Submit
                  </Button>
                </Flex>
              </form>
            </CardBody>
          </Card>
        )}
        {!user.username && (
          <Center py={6}>
            <Text>Please log in to add a comment</Text>
          </Center>
        )}
      </VStack>
    </Box>
  );
};

export default RecipePage;
